.autocomplete__input {
  box-sizing: border-box;
  font-size: 1em;
  padding: 1ch;
  margin: 1ch 0;
  width: 100%;
}

ul.autocomplete__items {
  line-height: 3ch;
  list-style: none;
  margin-block-end: 0;
  margin-block-start: 0;
  margin: 0;
  padding: 0;
}